<template>
  <transition name="menu">
    <form-organization :is-edit="false"/>
  </transition>
</template>

<script>
import formOrganization from './components/formOrganization'

export default {
  name: 'CreateformOrganization',
  components: { formOrganization }
}
</script>

